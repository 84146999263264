import React from 'react';

import Layout from '../components/Layout';

const DataProtectionPage = () => (
    <Layout fullMenu>
        <article id="main">
            <header>
                <h2>Datenschutzerklärung</h2>
            </header>
            <section className="wrapper style5">
                <div className="inner">
                    <h3>Datenschutz</h3>
                    <p>
                        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln
                        Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
                        Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                    </p>
                    <p>
                        Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich.
                        Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder
                        E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis.
                        Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                    </p>
                    <p>
                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
                        E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                        Dritte ist nicht möglich.</p>

                    <h3>Cookies</h3>
                    <p>
                        Diese Seite verwendet keine Cookies.
                    </p>

                    <h3>Server-Log- Files</h3>
                    <p>
                        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                        Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                    </p>
                    <p>
                        &bull;&nbsp;Browsertyp und Browserversion<br/>
                        &bull;&nbsp;verwendetes Betriebssystem<br/>
                        &bull;&nbsp;Referrer URL<br/>
                        &bull;&nbsp;Hostname des zugreifenden Rechners<br/>
                        &bull;&nbsp;Uhrzeit der Serveranfrage
                    </p>
                    <p>
                        Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit
                        anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu
                        prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
                    </p>

                    <h3>Recht auf Auskunft, Löschung, Sperrung</h3>
                    <p>
                        Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
                        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie
                        ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen
                        zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen
                        Adresse an uns wenden.
                    </p>

                    <h3>Widerspruch Werbe-Mails</h3>
                    <p>
                        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung
                        von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit
                        widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle
                        der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                    </p>

                    <p>
                        Quelle: <a href="https://www.e-recht24.de" target="_blank">https://www.e-recht24.de</a>
                    </p>
                </div>
            </section>
        </article>
    </Layout>
);

export default DataProtectionPage;
